const config = {
  //标题
  title: 'pdd照片',
  // baseUrl: 'https://proxy.pddphoto.cn/',
  // baseUrl: 'http://47.109.19.246:8000/',
  baseUrl: 'https://n-api.pddphoto.cn/',
  // baseUrl: 'http://36.212.147.248:8000/',
  designWidth: 750,
  whitePathList: []
}

export default config
